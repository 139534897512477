@import 'style/_imports.scss';

// nav

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 0;
    padding: 20px 40px;
    margin: 0;

    @include media($max, $break) {
      padding: 10px;
    }

    a {
      margin-left: 20px;
      font-family: 'Bebas Neue', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      color: #000;
      text-shadow: 2px 2px 0 rgba(#fff, 0.9);
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #e00;
        transform: rotate(-30deg);

        .logo {
          background-color: #e00;
          box-shadow: 0 0 0 2px #e00;
        }
      }
    }
  }
}

// logo

.logoContainer {
  margin-bottom: -20vw;
  display: flex;
  justify-content: flex-end;
  padding: 40px;
}

.logo {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #fff;
  background-color: #000;
  background-size: 4px 4px;
  box-shadow: 0 0 0 2px #000;
  color: #fff;
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 9px;
  font-weight: 700;
  font-family: 'Bebas Neue', sans-serif;
  text-transform: uppercase;
  text-shadow: none;
  transition: all 0.3s ease-in-out;
}

.txt {
  padding: 40px;

  @include media($max, $break) {
    padding: 20px;
    margin: 20px 0 80px;
  }

  h1 {
    margin: 0;
    line-height: 0.9;
    padding-bottom: 1vw;
    display: inline-block;
    font-size: 24vw;
    color: transparent;
    animation: colorChange 0.5s forwards linear;

    @include media($max, $break) {
      line-height: 1.1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 110%;
      background-color: #e00;
      transform: scale(0, 1);
      animation: reveal 2s forwards cubic-bezier(0.8, 0.2, 0.2, 1);
    }

    @for $i from 1 through 4 {
      &:nth-child(n + #{$i}) {
        animation-delay: $i * 0.3s + 0.3s;

        &:before {
          animation-delay: $i * 0.3s;
        }
      }
    }
  }
}

@keyframes colorChange {
  from {
    color: #fff;
  }
  to {
    color: #e00;
  }
}

@keyframes reveal {
  0% {
    transform-origin: left;
    transform: scale(0, 1);
  }
  20% {
    transform-origin: left;
    transform: scale(1, 1);
  }
  80% {
    transform-origin: right;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right;
    transform: scale(0, 1);
  }
}

.main {
  height: 100%;
  width: 100%;
  padding-bottom: 80px;

  img {
    max-height: 90vh;
    object-fit: contain;
    object-position: bottom;
    position: fixed;
    bottom: 0;
    left: calc(50% - 10vh);
    pointer-events: none;
    z-index: 2;

    @include media($max, $break) {
      max-height: 80vh;
    }
  }

  section {
    padding: 80px 40px 40px;
    width: 100%;
    z-index: 2;

    @include media($max, $break) {
      padding: 40px 20px 20px;
    }

    h2 {
      margin: 0 0 -12vw;
      padding: 1vw 1vw 2vw;
      line-height: 1;
      text-transform: uppercase;
      font-size: 15vw;
      white-space: nowrap;
      text-align: right;

      text-shadow: 4px 4px 0 rgba(#fff, 1);

      @include media($max, $break) {
        margin: 0 0 -17vw;
        text-shadow: 4px 4px 0 rgba(#fff, 0.9);
      }
    }

    .content {
      // background-color: rgba(#e00, 0.95);
      padding: 20px 0;

      @include media($min, $break) {
        font-size: 1.6rem;
        padding: 40px 0;
      }

      p {
        max-width: 80ch;
      }
    }
  }
}

// list

.list {
  margin: auto;
  display: table;

  h3 {
    display: inline-block;
    margin: 0;
    right: 0;
    text-transform: uppercase;

    span {
      background-color: #000;
      color: #fff;
      display: inline-block;
      padding: 20px 30px;
      z-index: 2;
      font-size: 2rem;
      white-space: nowrap;

      @include media($max, $break) {
        padding: 8px 16px;
        font-size: 1.6rem;
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 8px;
      top: 8px;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(#000 20%, transparent 20%),
        radial-gradient(#000 20%, transparent 20%);
      background-position: 0 0, 3px 3px;
      background-size: 6px 6px;
    }
  }
}

.listItem {
  display: table-row;
}

.listItemContent {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px 20px;

  @include media($max, $break) {
    display: block;
    padding: 0 0 20px 20px;
  }

  &:first-child {
    text-align: right;

    @include media($max, $break) {
      padding: 0;
      z-index: 2;
      text-align: left;
      margin-bottom: -10px;
    }
  }

  .contentContainer {
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 8px;
      top: 8px;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(#000 20%, transparent 20%),
        radial-gradient(#000 20%, transparent 20%);
      background-position: 0 0, 3px 3px;
      background-size: 6px 6px;
      z-index: -1;
    }

    .contentBackground {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #000;
      color: #000;
    }
  }
}

// .list {
//   @include media($min, $break) {
//     display: table;
//     border: 2px solid #000;
//     width: 100%;
//     background-color: rgba(#e00, 0.95);
//     // background-color: #fff;

//     .listItem {
//       display: table-row;

//       h3,
//       div {
//         display: table-cell;
//         border: 2px solid #000;
//         padding: 20px;
//         vertical-align: top;
//       }

//       h3 {
//         margin: 0;
//         text-transform: uppercase;
//         font-size: 2rem;
//         line-height: 1.1;
//         text-align: right;
//       }

//       div {
//         font-size: 2rem;
//       }
//     }
//   }
// }

.links {
  display: flex;
  justify-content: space-around;

  a {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    overflow: hidden;

    span {
      z-index: 2;
      // text-shadow: 2px 2px 0 #e00;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #e00;
      transform: translate(0, 100%);
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      &:before {
        transform: translate(0, 50%);
      }
    }

    svg {
      margin-right: 10px;
      // filter: drop-shadow(2px 2px 0 #e00);
    }
  }
}
