// gradients

$gradientBlue: linear-gradient(
  55deg,
  #0b73ae,
  #0b73ae 20%,
  #076fad 20%,
  #076fad 40%,
  #006cab 40%
);

// colors

$blue: #006dae;
$blueDark: #2e446c;
$red: #cb2322;
$gold: #f1be6e;

// shades

$greyLight: #f9fbfc;

// sizes

$standardWidth: 1000px;
$break: 720px;

// shadow

$shadow: 0 1px 2px rgba(#000, 0.03), 0 2px 4px rgba(#000, 0.03),
  0 4px 8px rgba(#000, 0.03), 0 8px 16px rgba(#000, 0.03),
  0 16px 32px rgba(#000, 0.03);
