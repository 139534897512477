@import 'style/_imports.scss';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inconsolata:wght@200..900&display=swap');

* {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
  background-color: #fff;
  scroll-behavior: smooth;
  // cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='40' viewBox='0 0 32 40'%3E%3Cpath fill='%230E0' fill-rule='evenodd' d='M0 0v40l12.045-15.986h19.861z'/%3E%3C/svg%3E%0A"),
  //   auto;

  @include media($max, $break) {
    font-size: 1.6rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-image: radial-gradient(#000 20%, transparent 20%),
    radial-gradient(#000 20%, transparent 20%);
  background-position: 0 0, 2px 2px;
  background-size: 4px 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(#000, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 700;
}

p {
  margin: 0 0 20px;

  &:last-child {
    margin: 0;
  }
}

.mounted,
.unmounted,
[transition='mounted'],
[transition='unmounted'] {
  transition: all 0.3s ease-in-out;
}

.unmounted,
[transition='unmounted'] {
  opacity: 0;
  transform: translate(0, -10px);
}

.mounted,
[transition='mounted'] {
  opacity: 1;
  transform: none;
}

.box {
  width: 200px;
  height: 200px;
  background-color: $gold;
  margin: 20px;
  box-shadow: shadow(4, 0.05, 8);
}

//

nav {
  z-index: 99;
}
