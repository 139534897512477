// media

$max: max-width;
$min: min-width;

@mixin media($type, $width) {
  @media screen and ($type: $width) {
    @content;
  }
}

// shadow

@function shadow($number: 4, $intensity: 0.05, $iterations: 4) {
  $box-shadow: '';
  @for $i from 1 through $iterations {
    $shadows: 0px
      #{$i *
      $number}px
      #{$i *
      2 *
      $number}px
      rgba(0, 0, 0, $intensity);
    $box-shadow: $box-shadow + $shadows + ',';
  }
  @return str_slice(unquote($box-shadow), 0, str_length($box-shadow)-1);
}
